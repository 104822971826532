import { Controller } from 'stimulus';
import * as $ from 'jquery';
import 'select2';
import 'select2/dist/js/i18n/ja';

import merge from 'lodash/merge';

export default class extends Controller {
  static targets = [];

  connect() {
    $(this.element).select2(
      merge(
        {
          theme: 'bootstrap4',
          language: 'ja',
          width: '100%',
        },
        this.options
      )
    );
  }

  disconnect() {
    $(this.element).select2('destroy');
  }

  get options() {
    if (this.data.has('options')) {
      return JSON.parse(this.data.get('options'));
    }
  }
}
