import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.onAjaxSuccess = this.onAjaxSuccess.bind(this);
    this.onAjaxError = this.onAjaxError.bind(this);
    this.onAjaxComplete = this.onAjaxComplete.bind(this);
  }

  connect() {
    this.element.addEventListener('ajax:success', this.onAjaxSuccess);
    this.element.addEventListener('ajax:error', this.onAjaxError);
    this.element.addEventListener('ajax:complete', this.onAjaxComplete);
  }

  disconnect() {
    this.element.removeEventListener('ajax:success', this.onAjaxSuccess);
    this.element.removeEventListener('ajax:error', this.onAjaxError);
    this.element.removeEventListener('ajax:complete', this.onAjaxComplete);
  }

  sendTestMail() {
    if (this.element.checkValidity()) {
      this.element.dataset.remote = 'true';
      this.element.dataset.originalConfirm = this.element.dataset.confirm;
      delete this.element.dataset.confirm;
    }
  }

  onAjaxSuccess(evt) {
    const [res] = evt.detail;
    alert(res.message);
  }

  onAjaxError(evt) {
    const [res] = evt.detail;
    alert(res.errors.join("\n"));
  }

  onAjaxComplete() {
    delete this.element.dataset.remote;
    this.element.dataset.confirm = this.element.dataset.originalConfirm;
    delete this.element.dataset.originalConfirm;
  }
}
