import { Controller } from 'stimulus';

import numberToHumanSize from './numberToHumanSize';

export default class extends Controller {
  static targets = [
    'root',
    'input',
    'changed',
    'filename',
    'filesize',
    'preview',
    'remove',
    'button',
  ];

  connect() {
    this.sync();
  }

  sync() {
    if (this.required) {
      this.rootTarget.classList.add(this.state('required'));
    } else {
      this.rootTarget.classList.remove(this.state('required'));
    }

    if (this.removed) {
      this.rootTarget.classList.add(this.state('removed'));
      this.inputTarget.disabled = true;
      Array.from(this.buttonTargets, btn => {
        btn.classList.add('disabled');
      });
    } else {
      this.rootTarget.classList.remove(this.state('removed'));
      this.inputTarget.disabled = false;
      Array.from(this.buttonTargets, btn => {
        btn.classList.remove('disabled');
      });
    }

    if (this.inputTarget.value) {
      this.beChanged();
      this.updatePreview();
    } else if (this.changed) {
      this.beChanged();
      this.updatePreview();
    } else if (this.present) {
      this.beNotChanged();
      this.updatePreview();
      this.bePresent();
    } else {
      this.beNotChanged();
      this.beNotPresent();
    }
  }

  onChange() {
    if (this.hasChangedTarget) {
      this.changedTarget.disabled = true;
    }
    this.changed = !!this.inputTarget.value;

    this.sync();
  }

  cancel() {
    if (this.inputTarget.value) {
      this.inputTarget.value = null;
    }

    if (this.hasChangedTarget) {
      this.changedTarget.disabled = true;
    }
    this.changed = false;

    this.sync();
  }

  bePresent() {
    this.rootTarget.classList.add(this.state('present'));
    this.inputTarget.removeAttribute('required');
  }

  beNotPresent() {
    this.rootTarget.classList.remove(this.state('present'));

    if (this.required) {
      this.inputTarget.setAttribute('required', 'required');
    }
  }

  beChanged() {
    this.rootTarget.classList.add(this.state('changed'));
  }

  beNotChanged() {
    this.rootTarget.classList.remove(this.state('changed'));
  }

  updatePreview() {
    if (this.inputTarget.files.length) {
      const file = this.inputTarget.files[0];
      if (this.hasFilenameTarget) {
        this.filenameTarget.textContent = file.name;
      }
      if (this.hasFilesizeTarget) {
        this.filesizeTarget.textContent = numberToHumanSize(file.size);
      }
      if (this.hasPreviewTarget) {
        this.previewTarget.innerHTML = '';
        this.previewTarget.appendChild(createPreviewElement(file));
      }
    } else {
      if (this.hasFilenameTarget) {
        this.filenameTarget.textContent = '';
      }
      if (this.hasFilesizeTarget) {
        this.filesizeTarget.textContent = '';
      }
      if (this.hasPreviewTarget) {
        this.previewTarget.innerHTML = '';
      }
    }
  }

  state(state) {
    return `attachment-upload--${state}`;
  }

  component(component) {
    return `attachment-upload__${component}`;
  }

  get required() {
    return this.data.has('required');
  }

  get present() {
    return this.data.has('present');
  }

  get changed() {
    return this.data.has('changed');
  }

  set changed(value) {
    if (value) {
      this.data.set('changed', true);
    } else {
      this.data.delete('changed');
    }
  }

  get removed() {
    return this.removeTarget.checked;
  }
}

function createPreviewElement(file) {
  if (/^image\//.test(file.type)) {
    const image = document.createElement('img');
    image.src = URL.createObjectURL(file);
    return image;
  } else if (/^video\//.test(file.type)) {
    const video = document.createElement('video');
    video.setAttribute('controls', true);

    const source = document.createElement('source');
    source.src = URL.createObjectURL(file);
    video.appendChild(source);

    return video;
  } else if (/^audio\//.test(file.type)) {
    const audio = document.createElement('audio');
    audio.setAttribute('controls', true);

    const source = document.createElement('source');
    source.src = URL.createObjectURL(file);
    audio.appendChild(source);

    return audio;
  }

  const i = document.createElement('i');
  i.setAttribute('class', 'fa fa-file');
  return i;
}
