import { Controller } from 'stimulus';

import VNode from 'virtual-dom/vnode/vnode';
import VText from 'virtual-dom/vnode/vtext';
import diff from 'virtual-dom/diff';
import patch from 'virtual-dom/patch';
import createElement from 'virtual-dom/create-element';
import htmlToVdom from 'html-to-vdom';

import Mustache from 'mustache';

import numberToHumanSize from './numberToHumanSize';

const convertHTML = htmlToVdom({ VNode: VNode, VText: VText });

export default class extends Controller {
  static targets = ['root', 'input', 'placeholder'];

  connect() {
    this.sync();
  }

  sync() {
    if (this.required) {
      this.rootTarget.classList.add(this.state('required'));
    } else {
      this.rootTarget.classList.remove(this.state('required'));
    }

    if (this.inputTarget.value) {
      this.beChanged();
      this.updatePreview();
      this.bePresent();
    } else if (this.changed) {
      this.beChanged();
      this.updatePreview();
    } else if (this.present) {
      this.beNotChanged();
      this.updatePreview();
      this.bePresent();
    } else {
      this.beNotChanged();
      this.beNotPresent();
    }
  }

  onChange() {
    this.sync();
  }

  cancel() {
    if (this.inputTarget.value) {
      this.inputTarget.value = null;
    }

    if (this.hasChangedTarget) {
      this.changedTarget.disabled = true;
    }
    this.changed = false;

    this.sync();
  }

  bePresent() {
    this.rootTarget.classList.add(this.state('present'));
    this.inputTarget.removeAttribute('required');
  }

  beNotPresent() {
    this.rootTarget.classList.remove(this.state('present'));

    if (this.required) {
      this.inputTarget.setAttribute('required', 'required');
    }
  }

  beChanged() {
    this.rootTarget.classList.add(this.state('changed'));
  }

  beNotChanged() {
    this.rootTarget.classList.remove(this.state('changed'));
  }

  updatePreview() {
    const files = [];
    Array.from(this.inputTarget.files, file => {
      files.push(file);
    });

    const html = Mustache.render(this.template, {
      files,
      filename: function() {
        return this.name;
      },
      filesize: function() {
        return numberToHumanSize(this.size);
      },
      audio: function() {
        return /^audio\//.test(this.type);
      },
      video: function() {
        return /^video\//.test(this.type);
      },
      image: function() {
        return /^image\//.test(this.type);
      },
      url: function() {
        return URL.createObjectURL(this);
      },
    });

    const newTree = convertHTML(html);
    if (this.placeholderNode) {
      const patches = diff(this.placeholderTree, newTree);
      this.placeholderTree = newTree;
      this.placeholderNode = patch(this.placeholderNode, patches);
    } else {
      this.placeholderNode = createElement(newTree);
      this.placeholderTree = newTree;
      this.placeholderTarget.appendChild(this.placeholderNode);
    }
  }

  state(state) {
    return `attachment-upload--${state}`;
  }

  component(component) {
    return `attachment-upload__${component}`;
  }

  get required() {
    return this.data.has('required');
  }

  get present() {
    return this.data.has('present');
  }

  set present(value) {
    if (value) {
      this.data.set('present', true);
    } else {
      this.data.delete('present');
    }
  }

  get changed() {
    return this.data.has('changed');
  }

  set changed(value) {
    if (value) {
      this.data.set('changed', true);
    } else {
      this.data.delete('changed');
    }
  }

  get template() {
    return JSON.parse(this.placeholderTarget.dataset.template);
  }
}
