import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [];

  connect() {}

  disconnect() {}

  refreshToken(e) {
    e.preventDefault();
    $.ajax({
      url: this.data.get('tokenUrl'),
      method: 'POST',
      dataType: 'json',
    })
      .done((data, status, xhr) => {
        if (status === 'success') {
          $(this.element)
            .find(`[name="authenticity_token"]`)
            .val(data.authenticity_token);
          $(this.element)
            .find('form')
            .submit();
        }
      })
      .catch((xhr, status, error) => {
        alert('ログインに失敗しました。');
      });
  }
}
