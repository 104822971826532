import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'target'];

  connect() {
	  console.info(this.element)
  }

  disconnect() {}

  sync(evt) {
    const checked = this.sourceTarget.checked;
    Array.from(this.targetTargets, target => {
      target.checked = checked;
    });
  }

  check() {
    Array.from(this.targetTargets, target => {
      target.checked = true;
    });
  }

  clear() {
    Array.from(this.targetTargets, target => {
      target.checked = false;
    });
  }
}
