import { Controller } from 'stimulus';

import izitoast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';

export default class extends Controller {
  static targets = [];

  connect() {
    if (this.data.get('id')) {
      const toast = document.querySelector(`#${this.data.get('id')}`);
      if (toast) {
        toast.parentNode.removeChild(toast);
      }
      return;
    }

    const title = this.data.get('title') || '';
    const message = this.data.get('message') || '';
    const type = this.data.get('type') || 'info';
    const position = this.data.get('position') || 'topCenter';
    const timeout = this.data.get('timeout') || 5000;

    const id = 'toast-' + generateId();

    this.data.set('id', id);

    izitoast[type].call(izitoast, {
      id,
      title,
      message,
      position,
      timeout,
      onClosed: () => {
        this.element.parentNode.removeChild(this.element);
      },
    });

    // トーストがキャッシュされないようにする
    document.addEventListener('turbolinks:before-cache', this.destroy);
  }

  disconnect() {
    if (this.data.has('id')) {
      const id = this.data.get('id');
      const toast = document.querySelector(`#${id}`);
      if (toast) {
        toast.parentNode.removeChild(toast);
      }
    }

    document.removeEventListener('turbolinks:before-cache', this.destroy);
  }

  destroy() {
    izitoast.destroy();
  }
}

function generateId() {
  const chars = [];
  const codeA = 'A'.charCodeAt(0);
  for (let i = 0; i < 32; i++) {
    chars.push(String.fromCharCode(codeA + Math.floor(Math.random() * 26)));
  }
  return chars.join('');
}
