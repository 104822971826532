import { Controller } from 'stimulus';
import * as $ from 'jquery';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [];

  connect() {
    const options = this.data.has('options')
      ? JSON.parse(this.data.get('options'))
      : {};

    this.sortable = new Sortable(this.element, Object.assign(options, {onUpdate: this.onUpdate}));
  }

  disconnect() {
    this.sortable.destroy();
  }

  onUpdate(evt) {
    $(evt.to).find('[name$="[position]"]').each(function(index) {
      $(this).val(index + 1);
    });
  }
}
