import { Application } from 'stimulus';

import './attachment_upload.scss';

import OneController from './controllers/attachment_upload/one_controller';
import ManyController from './controllers/attachment_upload/many_controller';

const application =
  Application.singleton || (Application.singleton = Application.start());

application.register('attachment-upload--one', OneController);
application.register('attachment-upload--many', ManyController);
