import { Controller } from 'stimulus';

import PerfectScrollbar from 'perfect-scrollbar';

/*
 * paper-dashboard のふるまいを body タグに追加するコントローラー。
 * paper-dashboard.js を置き換える。
 */
export default class extends Controller {
  static targets = ['sidebarToggle', 'bodyClick'];

  initialize() {
    this.isWindows = navigator.platform.indexOf('Win') > -1;
    this.pss = [];

    this.closeSidebar = this.closeSidebar.bind(this);
  }

  connect() {
    this.initPerfectScrollbar();
    this.initSidebar();

    // turbolinks で移動するときはサイドバーを閉じる
    document.addEventListener('turbolinks:visit', this.closeSidebar);

    // sidebar 開閉ボタンを controller の制御下に入れる
    Array.from(
      this.element.querySelectorAll(
        '.main-panel .navbar.fixed-top .navbar-toggle'
      ),
      toggle => {
        toggle.setAttribute(
          'data-target',
          `${this.scope.identifier}.sidebarToggle`
        );
        toggle.setAttribute(
          'data-action',
          `click->${this.scope.identifier}#toggleSidebar`
        );
      }
    );
  }

  disconnect() {
    this.removePerfectScrollbar();

    document.removeEventListener('turbolinks:visit', this.closeSidebar);
  }

  initPerfectScrollbar() {
    if (this.isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      const psTargets = this.element.querySelectorAll(
        '.sidebar .sidebar-wrapper, .main-panel'
      );
      Array.from(psTargets, container => {
        const ps = new PerfectScrollbar(container);
        this.pss.push(ps);
      });

      this.element.parentNode.classList.add('perfect-scrollbar-on');
    }
  }

  removePerfectScrollbar() {
    Array.from(this.pss, ps => ps.destroy());
    this.pss.length = 0;
  }

  initSidebar() {
    if (this.sidebarOpen) {
      this.openSidebar(0);
    }
  }

  toggleSidebar() {
    if (this.sidebarOpen) {
      this.closeSidebar();
    } else {
      this.openSidebar(580);
    }
  }

  openSidebar(delay) {
    if (!this.hasBodyClickTarget) {
      const bodyClick = document.createElement('div');
      bodyClick.setAttribute('id', 'bodyClick');
      bodyClick.setAttribute(
        'data-target',
        `${this.scope.identifier}.bodyClick`
      );
      bodyClick.setAttribute(
        'data-action',
        `click->${this.scope.identifier}#closeSidebar`
      );
      this.element.appendChild(bodyClick);
    }

    this.sidebarOpen = true;
    if (delay) {
      setTimeout(() => {
        this.sidebarToggled = true;
      }, delay);
    } else {
      this.sidebarToggled = true;
    }
  }

  closeSidebar() {
    this.sidebarOpen = false;
    this.sidebarToggled = false;

    if (this.hasBodyClickTarget) {
      Array.from(this.bodyClickTargets, bodyClick => {
        bodyClick.parentNode.removeChild(bodyClick);
      });
    }
  }

  get sidebarOpen() {
    return this.element.parentNode.classList.contains('nav-open');
  }

  set sidebarOpen(value) {
    if (value) {
      this.element.parentNode.classList.add('nav-open');
    } else {
      this.element.parentNode.classList.remove('nav-open');
    }
  }

  set sidebarToggled(value) {
    if (this.hasSidebarToggleTarget) {
      if (value) {
        this.sidebarToggleTarget.classList.add('toggled');
      } else {
        this.sidebarToggleTarget.classList.remove('toggled');
      }
    }
  }
}
