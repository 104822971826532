const UNITS = [
  [1024 * 1024 * 1024, 'ギガバイト'],
  [1024 * 1024, 'メガバイト'],
  [1024, 'キロバイト'],
  [1, 'バイト']
];

export default function numberToHumanSize(size) {
  var unit = UNITS[UNITS.length - 1][1];

  for (let i = 0; i < UNITS.length; i++) {
    if (size < UNITS[i][0]) {
      continue;
    }
    size = size * 1.0 / UNITS[i][0];
    unit = UNITS[i][1];
    break;
  }

  return Number(size).toLocaleString( undefined, { maximumFractionDigits: 1 }) + unit;
}
