import { Controller } from 'stimulus';
import $ from 'jquery';

import 'select2';
import 'select2/dist/js/i18n/ja';

import find from 'lodash/find';
import merge from 'lodash/merge';

function formatOption(state) {
  if (!state.id) {
    return state.text;
  }
  if (state.attributes) {
    return `${state.attributes.code} ${state.attributes.name}`;
  }
  return state.text;
}

export default class extends Controller {
  static targets = ['member', 'emergencyContact'];

  initialize() {
    this.onSelecting = this.onSelecting.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  connect() {
    const selectOptions = $(this.memberTarget).data();

    $(this.memberTarget)
      .select2(
        merge(selectOptions, {
          theme: 'bootstrap4',
          language: 'ja',
          ajax: {
            url: this.data.get('candidates'),
            dataType: 'json',
            data: function(params) {
              return {
                code_or_name_or_name_kana_cont: params.term,
                page: params.page || 1,
              };
            },
            processResults: function(data) {
              return {
                results: data.data,
                pagination: {
                  more: !!(data.links && data.links.next),
                },
              };
            },
          },
          templateResult: formatOption,
          templateSelection: formatOption,
        })
      )
      .on('select2:selecting', this.onSelecting)
      .on('select2:select', this.onSelect);
  }

  disconnect() {
    $(this.memberTarget)
      .select2('destroy')
      .off('select2:selecting', this.onSelecting)
      .off('select2:select', this.onSelect);
  }

  onSelecting(e) {
    const select = $(this.memberTarget);
    const memberId = select.val();
    const selecting = find(select.select2('data'), { id: memberId });
    if (
      selecting &&
      selecting.id &&
      selecting.attributes &&
      $(this.emergencyContactTarget).val() === selecting.attributes.phone
    ) {
      $(this.emergencyContactTarget).val('');
    }
  }

  onSelect(e) {
    const data = e.params.data;
    if (data.selected && data.attributes && !$(this.emergencyContactTarget).val()) {
      $(this.emergencyContactTarget).val(data.attributes.phone);
    }
  }
}
